import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EnvService } from 'src/app/shared/common/services/env.service';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { environment } from 'src/environments/environment';
import { PaymentConfig } from '../../../../app.interfaces';
import { EPaymentMethodTypes } from '../../payments/enums/payment-methods.enum';
import { EBillingAddressType } from './credit-card-form.component';

@Injectable({ providedIn: 'root' })
export class CreditCardFormService {
  private url: string;
  private onChangeCardNumberSubject: Subject<boolean>;

  constructor(
    private env: EnvService,
    private config: ConfigService,
    private http: CheckinHttpClient
  ) {
    this.url = `${this.env.endpoint}/v2/card/`;
    this.onChangeCardNumberSubject = new Subject();
  }

  private getTokenizeParams(billingAddress: any): Observable<any> {
    const url = `${this.url}payments/${this.config[this.config.project].hash}?${new URLSearchParams(billingAddress).toString()}`;

    return this.http.get(url);
  }

  public tokenize(params: {
    billingAddress: {
      street: string;
      city: string;
      postalCode: string;
      province: string;
      country: number;
      firstname: string;
      lastname: string;
      email: string;
      phoneNumber: string;
    };
    creditCard: {
      name: string;
      number: string;
      cvc: string;
      expiryDate: string;
    };
  }): Observable<any> {
    const { billingAddress, creditCard: cc } = params;

    return this.getTokenizeParams(billingAddress).pipe(
      switchMap((tokenizeParams) => {
        const {
          gatewayCode: code,
          gatewayKeyId: keyId,
          customer,
          booking
        } = tokenizeParams;

        const creditCard = _.cloneDeep(cc);
        creditCard.number = creditCard.number.replace(/\s/g, '');

        const deviceId = (window as any).MP_DEVICE_SESSION_ID;


        const body = {
          code,
          keyId,
          creditCard,
          customer,
          booking,
          metadata: { app: 'INTRANET' },
          ...deviceId && {
            deviceId
          }
        };

        return this.http.post(`${environment.payments.url}/gateways/tokenize`, body);
      }),
      switchMap((res: any) => {
        this.config.booking.creditCard.set({id: res.id});
        return this.http.post(
          `${this.url}payments/${this.config[this.config.project].hash}`,
          { token: res.id, billingAddress }
        ).pipe((
          map((r: {
            billingAddress: { [key: string]: any; };
            display: string;
            holder: string;
            scheme: string;
            expMonth: string;
            expYear: string;
          }) => r)
        ));
      }),
      catchError((error: any) => {
        const errorCode = _.get(error, 'error.error.code', 'Server error');

        return throwError(errorCode);
      })
    );
  }

  public purchase(params: {
    mcp?: PaymentConfig['mcp'];
    paymentMethodType: EPaymentMethodTypes;
    paymentMethodData?: {
      bankCountry?: string;
    };
    tokenId?: number;
  } = { paymentMethodType: EPaymentMethodTypes.CREDITCARD }): Observable<any> {
    const device: { [key: string]: any; } = {
      navigator: {
        lang: this.config.locale.get(),
        javaEnabled: window.navigator.javaEnabled(),
        timezone: new Date().getTimezoneOffset()
      },
      screen: {
        colorDepth: window.screen.colorDepth,
        height: window.screen.height,
        width: window.screen.width
      }
    };

    const deviceId = (window as any).MP_DEVICE_SESSION_ID;

    return this.http.post(`${this.url}purchase/${this.config[this.config.project].hash}`, {
      device,
      paymentMethodType: params.paymentMethodType,
      ...(params.paymentMethodData) && {
        paymentMethodData: params.paymentMethodData
      },
      ...params.mcp && params.mcp.enabled && {
        mcp: params.mcp
      },
      ...(params.tokenId) && {
        tokenId: params.tokenId
      },
      ...deviceId && {
        deviceId
      }
    }).pipe((
      map((res: { url: string; }) => res),
      catchError((error: any) => throwError(error || 'Server error')
    )));
  }

  public getBillingAddressConfig(): Observable<{
    billingAddressType: EBillingAddressType;
    paymentsSCA: boolean;
  }> {
    return this.http.get(`${this.url}billing-address-config`).pipe(
      map((data: {
        billingAddressType: EBillingAddressType;
        paymentsSCA: boolean;
      }) => data),
      catchError((error: any) => throwError(error || 'Server error')
    ));
  }

  public updateBillingAddress(params: {
    billingAddress: {
      street: string;
      city: string;
      postalCode: string;
      province: string;
      country: number;
      firstname: string;
      lastname: string;
      email: string;
    };
  }): Observable<any> {
    return this.http.post(`${this.url}billing-address/${this.config.booking.id}`, params).pipe((
      catchError((error: any) => throwError(error || 'Server error')
    )));
  }

  public onChangesCardNumber(): Observable<boolean> {
    return this.onChangeCardNumberSubject.asObservable();
  }

  public updatedCardNumber(): void {
    this.onChangeCardNumberSubject.next(true);
  }
}
