import { Component, OnInit, Input } from '@angular/core';
import { InputBase } from '../input-base';
import { GeoService } from 'src/app/shared/common/services/geo.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import {
  AutoSuggestResponse,
  AutoSuggestOptions,
  AutoSuggestOptionsMap,
  AutoSuggestItem
} from './input-autosuggest.interface';
import { filter, map } from 'rxjs/operators';

const ENTITIES_MAP: {
  [key in AutoSuggestOptions]: AutoSuggestOptionsMap
} = {
  city: 'idCity',
  province: 'idProvince',
  region: 'idRegion',
  country: 'idCountry'
};

const criteriaKey = {
  clientCity: 'city',
  clientProvince: 'province',
  clientRegion: 'region',
  clientCountry: 'country',
  country: 'country',
  region: 'region'
};

@Component({
  selector: 'app-input-autosuggest',
  templateUrl: './input-autosuggest.component.html',
  styleUrls: ['./input-autosuggest.component.scss']
})

export class InputAutosuggestComponent extends InputBase implements OnInit {
  @Input() entity: AutoSuggestOptions;
  @Input() value: string;
  @Input() useControlValueInsteadValue: boolean;
  @Input() criteria: string;
  @Input() disabledWithValue: boolean;

  autoSuggestControl: string;
  autoSuggestCursor: number;
  private autoSuggestDebounce: any;
  autoSuggestDebounceDelay: number;
  autoSuggestFocused: boolean;
  autoSuggestForm: FormGroup;
  autoSuggestLoading: boolean;
  autoSuggestOptions: AutoSuggestResponse;

  showAutoSuggestList: boolean;

  constructor(
    formBuilder: FormBuilder,
    private geoService: GeoService
  ) {
    super();
    this.autoSuggestControl = 'autoSuggestControl';
    this.autoSuggestCursor = -1;
    this.autoSuggestDebounceDelay = 400;
    this.autoSuggestForm = formBuilder.group({
      [this.autoSuggestControl]: new FormControl()
    });
    this.autoSuggestOptions = [];
  }

  ngOnInit(): void {
    if (this.useControlValueInsteadValue) {
      this.value = this.form.get(this.control).value;
    }

    if (this.value) {
      const control = this.autoSuggestForm.get(this.autoSuggestControl);
      control.setValue(this.value, { onlySelf: true, emitEvent: false });

      const hasSpecialCharacter = /[!#$%^&*(),.?":{}|<>_-]/.test(this.value) && !/[+@]/.test(this.value);
      if (!hasSpecialCharacter && this.disabledWithValue) {
        control.disable();
      }
    }

    this.onControlChanges();
  }

  onControlChanges(): void {
    this.autoSuggestForm.get(this.autoSuggestControl).valueChanges.subscribe((v) => {
      const value = v ? v.trim() : '';

      if (!value) {
        this.resetAutoSuggestConfig();
        return;
      }

      this.autoSuggestLoading = true;
      this.showAutoSuggestList = false;

      this.getAutoSuggestOptions(value);
    });
  }

  getAutoSuggestOptions(value: string): void {
    if ((this.control === 'clientBirthPlace' || this.control === 'clientIdIssuedAt')) {
      this.entity = 'city';
    }

    if (this.autoSuggestDebounce) {
      clearTimeout(this.autoSuggestDebounce);
    }

    const criteriaCondition = (this.criteria) ? this.getCriteriaCondition() : '';

    this.autoSuggestDebounce = setTimeout(() => {
      this.geoService.getEntityByKey(value, this.entity, criteriaCondition).subscribe(
        (res: AutoSuggestResponse) => {
          if ((this.control === 'clientBirthPlace' || this.control === 'clientIdIssuedAt') && (!res || res.length === 0)) {
            this.entity = 'country';
            this.geoService.getEntityByKey(value, this.entity, criteriaCondition).subscribe(
              (countryRes: AutoSuggestResponse) => {
                this.updateAutoSuggestOptions(countryRes);
              }
            );
          } else {
            this.updateAutoSuggestOptions(res);
          }
        }
      );
    }, this.autoSuggestDebounceDelay);
  }

  private updateAutoSuggestOptions(res: AutoSuggestResponse): void {
    this.autoSuggestCursor = -1;
    this.autoSuggestOptions = res;
    this.autoSuggestLoading = false;
    this.showAutoSuggestList = true;

    if (!this.autoSuggestFocused) {
      this.updateAutoSuggestValue();
    }
  }

  onKeyDown(evt: any): void {
    const arrowKeys = [13, 38, 40];

    if (arrowKeys.includes(evt.keyCode)) {
      const keysMap = new Map();
      keysMap.set(13, this.updateAutoSuggestValue.bind(this));
      keysMap.set(38, this.decreaseAutoSuggestCursor.bind(this));
      keysMap.set(40, this.increaseAutoSuggestCursor.bind(this));
      keysMap.get(evt.keyCode)();
    }
  }

  updateAutoSuggestValue(): void {
    this.showAutoSuggestList = false;

    const selectedOption = this.autoSuggestOptions[this.autoSuggestCursor];

    if (selectedOption) {
      const { [this.entity]: value, [ENTITIES_MAP[this.entity]]: code } = selectedOption;

      this.autoSuggestForm.get(this.autoSuggestControl).setValue(value, { onlySelf: true, emitEvent: false });
      this.setFormValue(code, value, this.entity);

      return;
    }

    if (!this.autoSuggestValue) {
      this.setFormValue(null, '', '');

      return;
    }

    if (this.autoSuggestOptions.length === 0) {
      this.setFormValue(null, this.autoSuggestValue, '');

      return;
    }

    const criteriaCondition = (this.criteria) ? this.getCriteriaCondition() : '';

    this.geoService.getEntityByKey(this.autoSuggestValue, this.entity, criteriaCondition)
      .pipe(
        filter((res: AutoSuggestResponse) => res.length >= 1),
        map((res: AutoSuggestResponse) => res[0])
      )
      .subscribe((resultOption: AutoSuggestItem) => {
        const {
          [this.entity]: resultValue,
          [ENTITIES_MAP[this.entity]]: resultCode
        } = resultOption;

        if (this.autoSuggestValue.toLowerCase() !== resultValue.toLowerCase()) {
          this.setFormValue(null, this.autoSuggestValue, '');
          return;
        }

        this.setFormValue(resultCode, resultValue, this.entity);
      });
  }

  setAutoSuggestCursor(cursor: number): void {
    this.autoSuggestCursor = cursor;
  }

  increaseAutoSuggestCursor(): void {
    const { length } = this.autoSuggestOptions;
    const max = length > 0 ? length : -1;

    this.setAutoSuggestCursor(Math.min(max, (this.autoSuggestCursor + 1)));
  }

  decreaseAutoSuggestCursor(): void {
    this.setAutoSuggestCursor(Math.max(-1, (this.autoSuggestCursor - 1)));
  }

  resetAutoSuggestConfig(): void {
    this.showAutoSuggestList = false;

    this.autoSuggestCursor = -1;
    this.autoSuggestForm.get(this.autoSuggestControl).reset(null, { onlySelf: true, emitEvent: false });
    this.autoSuggestOptions = [];
    this.form.get(this.control).reset();
  }

  toggleAutoSuggestFocused(): void {
    this.autoSuggestFocused = !this.autoSuggestFocused;
  }

  onAutoSuggestBlur(): void {
    this.toggleAutoSuggestFocused();
    this.updateAutoSuggestValue();
  }

  get autoSuggestValue(): string {
    const { autoSuggestControl } = this.autoSuggestForm.value;
    return autoSuggestControl || '';
  }

  setFormValue(code, value, entity): void {
    this.form.get(this.control).setValue({ code, value, entity });
  }

  getCriteriaCondition() {
    let criteriaCondition = '';
    const criteriaControl = this.form.get(this.criteria) || {value: ''};

    if (!criteriaControl.value) {
      return criteriaCondition;
    }

    // tslint:disable-next-line:max-line-length
    const criteriaValue = (this.criteria === 'clientCountry' || this.criteria === 'country') ? criteriaControl.value : criteriaControl.value.code;

    if (criteriaValue) {
      criteriaCondition = `${criteriaKey[this.criteria]}:${criteriaValue}`;
    }

    return criteriaCondition;
  }
}
