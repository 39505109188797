export enum ERoutes {
  BOOKING = 'booking'
}

export enum EPaymentMethodsNavigation {
  TPV_CC = 'purchase-card',
  CREDITCARD = 'purchase-card',
  CC_RETENCION = 'purchase-card',
  CHOOSE = 'choose',
  IDEAL = 'purchase-ideal',
  GOOGLE_PAY = 'purchase-google',
  APPLE_PAY = 'purchase-apple',
  PAYPAL = 'purchase-paypal',
  SOFORT = 'purchase-sofort',
  KLARNA = 'purchase-klarna',
  BANCONTACT = 'purchase-bancontact'
}
