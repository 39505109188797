import * as _ from 'lodash';
import { ProjectConfig as IPaymentsConfig } from 'src/app/shared/classes/projects/project-config.class';
import { IConfig } from 'src/app/app.interfaces';
import { PaymentsList, PaymentsListItem } from 'src/app/shared/modules/sidebar/booking-detail/payments-list/payments-list.interfaces';

export class PaymentsConfig extends IPaymentsConfig {
  public config: {
    template: 'success' | 'purchase' | 'tokenize' | 'purchase-ideal' | 'purchase-sofort';
    status: {
      success: boolean;
      error: boolean;
      pending: boolean;
    };
    waysOfPay: {
      numberOfMethods: number;
      typesIncludes: string[];
    },
    selectedPayment: PaymentsListItem;
    sourceApp: string;
  };

  private waysOfPayEnum = {
    CREDITCARD: [2, 3, 4, 20, 24, 25, 31, 38, 41, 43, 45, 52, 61],
    IDEAL: [56],
    SOFORT: [57],
    GOOGLE_PAY: [63],
    APPLE_PAY: [64],
    PAYPAL: [65],
    KLARNA: [66],
    BANCONTACT: [78]
  };

  constructor(params: IConfig['extraConfig'], payments: PaymentsList) {
    super(params);

    this.setConfig(params, payments);
  }

  private setConfig(params: IConfig['extraConfig'], payments: PaymentsList): void {
    const selectedPayment: PaymentsListItem = [...(_.get(payments, 'deposit', [])), ...(_.get(payments, 'paymentsDetail', []))]
      .find((p: PaymentsListItem) => p.selected);
    const status = {
      success: _.get(params, 'callback.result') === 'ok',
      error: _.get(params, 'callback.result') === 'ko',
      pending: !['ok', 'ko'].includes(_.get(params, 'callback.result', ''))
    };

    const waysOfPayOptions = _.get(params, 'waysOfPay', '');
    const waysOfPayConfig =  Object.entries(this.waysOfPayEnum).reduce((config, [key, value]) => {
      if (waysOfPayOptions.some(item => (value.includes(item)))) {
        config.typesIncludes.push(key);
        config.numberOfMethods++;
      }

      return config;
    }, {
      typesIncludes: [],
      numberOfMethods: 0
    });

    this.config = {
      template: status.success ? 'success' : (selectedPayment ?
        (_.get({
          IDEAL: 'purchase-ideal',
          SOFORT: 'purchase-sofort'
        }, selectedPayment.method, 'purchase')) :
        'tokenize'
      ),
      status,
      waysOfPay: waysOfPayConfig,
      selectedPayment,
      sourceApp: 'payments'
    };
  }
}
